/* eslint-disable */
/**
 * Handles the management of the data and state, and any utilities
 * needed to manipulate the data.
 */
import Honeybadger from "@honeybadger-io/js";
import axios from "axios";
import isEqual from "lodash.isequal";
import jp from "jsonpointer";
import participantRoles from "@/enums/participant-roles";
import transactionStates from "@/enums/transaction-states";
import uniqWith from "lodash.uniqwith";
import useAuth from "@/composables/auth";
import useEnumUtils from "@/enums/utils";
import useVerifiedClaims from "@/composables/verifiedClaims";
import useVerifiedClaimsUtils from "./verifiedClaimsUtils";
import { MOVEREADY_PDTF_API_URL } from "@/config";
import { getAppCheckToken } from "@/firebase";
import {
  getSubschema,
  getSubschemaValidator,
  getTransactionSchema,
} from "@pdtf/schemas";
import { ref, watchEffect } from "vue";
import traverse from "traverse";

const cannedExtractionResults = require("../../tests/mocks/cannedExtractionResults.json");

const attachments = ref({});
const formAttachments = ref([]);
const pdtfState = ref({});
const transactionError = ref(null);
const transactionId = ref(null);
const { getAccessToken } = useAuth();
const { verifiedClaims } = useVerifiedClaims();
const { getClaimsMap } = useVerifiedClaimsUtils();
const pdtfStateVerification = ref({});
const claimsMap = ref({});

const findTransactionsByUser = async (userId) => {
  const userAccessToken = getAccessToken();
  const appCheckToken = await getAppCheckToken();
  s;
  try {
    const response = await axios.get(
      `${MOVEREADY_PDTF_API_URL}/transactions/users/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );

    return response.data;
  } catch (ex) {
    if (ex.response.status === 404) return [];

    throw new Error(ex);
  }
};

const findTransactionsByUprn = async (
  uprn,
  status = transactionStates.ACTIVE
) => {
  try {
    const userAccessToken = getAccessToken();
    const appCheckToken = await getAppCheckToken();
    const response = await axios.get(
      `${MOVEREADY_PDTF_API_URL}/transactions/search`,
      {
        params: { uprn, status },
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );

    return response.data;
  } catch (ex) {
    if (ex.response?.status === 404) return [];

    throw ex;
  }
};

// Deprecated, move to transaction composable.
const fetchTransaction = async (newTransactionId) => {
  transactionId.value = newTransactionId;
  pdtfState.value = { transactionId: newTransactionId };
  attachments.value = {};
};

const createTransaction = async ({ userId = "", role = "", office = null }) => {
  const { getKeyByValue } = useEnumUtils();
  const roleEnumKey = getKeyByValue(role, participantRoles);

  try {
    const userAccessToken = getAccessToken();
    const appCheckToken = await getAppCheckToken();
    const transactionPayload = { role: roleEnumKey };

    if (office) {
      transactionPayload.office = office;
    }

    const response = await axios.post(
      `${MOVEREADY_PDTF_API_URL}/transactions/${userId}`,
      transactionPayload,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );

    if (response.data?.transactionId) {
      transactionId.value = response.data.transactionId;
      transactionError.value = null;
    } else {
      transactionId.value = null;
      throw new Error("Missing transaction Id");
    }
  } catch (ex) {
    transactionError.value = ex;
    Honeybadger.notify(ex, {
      message: "A transaction was not created",
      name: "pdtf.js",
      params: {
        userId,
        role,
      },
    });
    throw ex;
  }
};

const createStateFromVerifiedClaims = (
  newVerifiedClaims = [],
  oldState = {}
) => {
  // eslint-disable-next-line
  const updatedState = structuredClone(oldState);
  // eslint-disable-next-line
  const updatedStateVerification = structuredClone(pdtfStateVerification.value);
  newVerifiedClaims.forEach((verifiedClaim) => {
    const { claims } = verifiedClaim;
    Object.keys(claims).forEach((path) => {
      try {
        jp.set(updatedStateVerification, path, verifiedClaim.verification);
        jp.set(updatedState, path, claims[path]);
      } catch (ex) {
        throw new Error(`Cannot parse claim data for ${path}`);
      }
    });
  });
  pdtfStateVerification.value = updatedStateVerification;
  // duplicated for now, until we refactor the other verification methods
  claimsMap.value = getClaimsMap(newVerifiedClaims);
  return updatedState;
};

const createFormAttachmentsFromVerifiedClaims = (
  newVerifiedClaims = [],
  oldArray = []
) => {
  const updatedFormAttachments = [...oldArray];
  newVerifiedClaims.forEach((verifiedClaim) => {
    const { verification } = verifiedClaim;
    if (Array.isArray(verification?.evidence)) {
      verification.evidence.forEach((evidenceItem) => {
        const { type, record, attachments = [] } = evidenceItem;
        attachments.forEach((attachment) => {
          if (attachment.pdtfSchemaPath) {
            updatedFormAttachments.push({
              attachment,
              type,
              record,
            });
          }
        });
      });
    }
  });
  return updatedFormAttachments;
};

/**
 * A bit convoluted. Given a claim can have multiple paths within
 * it, the pdtfSchemaPath attachments were incorrectly being applied
 * to each of those paths, resulting in multiples of the same attachment
 * collated together.
 */
const createAttachmentsFromVerifiedClaims = (
  newVerifiedClaims = [],
  oldState = {}
) => {
  // eslint-disable-next-line
  const updatedAttachments = structuredClone(oldState);
  const formAttachments = [];
  newVerifiedClaims.forEach((verifiedClaim) => {
    const { claims, verification } = verifiedClaim;

    verification.evidence.forEach((evidenceItem) => {
      const { type, record, attestation, attachments = [] } = evidenceItem;

      const attachmentWithMetaData = attachments.map((attachment) => {
        return {
          attachment,
          type,
          record,
          attestation,
        };
      });
      const collectorAttachments2 = attachmentWithMetaData.filter(
        (attachmentWithMetaDataItem) => {
          if (attachmentWithMetaDataItem?.attachment?.pdtfSchemaPath) {
            return true;
          }
          return false;
        }
      );
      formAttachments.push(...collectorAttachments2);
    });

    Object.keys(claims).forEach((path) => {
      try {
        if (Array.isArray(verification?.evidence)) {
          verification.evidence.forEach((evidenceItem) => {
            const {
              type,
              record,
              attestation,
              attachments = [],
            } = evidenceItem;

            const attachmentWithMetaData = attachments.map((attachment) => {
              return {
                attachment,
                type,
                record,
                attestation,
              };
            });

            const collectorAttachments = attachmentWithMetaData.filter(
              (attachmentWithMetaDataItem) =>
                !attachmentWithMetaDataItem?.attachment?.pdtfSchemaPath
            );

            // Exclude any attachments with a pdtfSchemaPath.
            jp.set(
              updatedAttachments,
              `${path}/attachments/-`,
              collectorAttachments
            );
          });
        }
      } catch (ex) {
        throw new Error(`Cannot parse claim data for ${path}`);
      }
    });
  });

  // Attach any pdtfSchemaPath files in each claim to the pdtfSchemaPath not every path in the claim.
  formAttachments.forEach((formAttachment) => {
    jp.set(
      updatedAttachments,
      `${formAttachment.attachment.pdtfSchemaPath}/attachments/-`,
      formAttachment
    );
  });

  return updatedAttachments;
};

const setStateByPath = (pathToUpdate, newValue) => {
  jp.set(pdtfState.value, pathToUpdate, newValue);
};

const getStateByPath = (pathToFind, defaultValue = {}, state) => {
  try {
    const matchedState = jp.get(state || pdtfState.value, pathToFind);
    return matchedState || defaultValue;
  } catch (error) {
    return {};
  }
};

const getAttachmentsByPath = (pathToFind, onlyAtExactPath) => {
  try {
    const matchedState = jp.get(attachments.value, pathToFind);
    let totalAttachments = [];

    if (!matchedState) return [];

    // In the BASPI view, the attachments were being multiplied for each parent/child.
    if (onlyAtExactPath) {
      const items = matchedState?.attachments || [];
      const flattenedItems = [].concat.apply([], items);

      return flattenedItems;
    }

    const findAttachments = (items) => {
      if (Array.isArray(items)) {
        // Somewhere, attachments are added as an array item. This flattens them.
        const mappedItems = items.reduce((acc, item) => {
          if (item.attachments) return [...acc, ...item.attachments];

          return [...acc, item];
        }, []);

        const flattenedItems = [].concat.apply([], mappedItems);
        totalAttachments = [...totalAttachments, ...flattenedItems];

        return;
      } else {
        Object.keys(items).forEach((pathName) => {
          findAttachments(items[pathName]);
        });
      }
    };
    findAttachments(matchedState);
    /**
     * Saving partial claims with page-level file upload
     * causes the duplicated files. This is a temporary
     * fix until there is better management of file
     * association.
     */

    const dedupedAttachments = uniqueAttachments(totalAttachments);

    return dedupedAttachments;
  } catch (ex) {
    return [];
  }
};

const uniqueAttachments = (dirtyAttachments) => {
  return uniqWith(dirtyAttachments, isEqual);
};

const getVerifiedClaimsByPath = (pathToFind) => {
  return verifiedClaims.value.filter((verifiedClaim) => {
    return Object.keys(verifiedClaim.claims).some((claimsPath) => {
      // TODO should only include parent level claims if that claim contains
      // data relevant to this path
      return (
        claimsPath.startsWith(pathToFind) || pathToFind.startsWith(claimsPath)
      );
    });
  });
};

watchEffect(() => {
  pdtfState.value = createStateFromVerifiedClaims(verifiedClaims.value, {
    transactionId: transactionId.value,
  });
  /**
   * This is double the work, but keep it separated for now
   * in case the file management changes, so it's easier to unwrap
   * and a cleaner separation of concerns.
   */
  attachments.value = createAttachmentsFromVerifiedClaims(verifiedClaims.value);
  formAttachments.value = createFormAttachmentsFromVerifiedClaims(
    verifiedClaims.value
  );
  claimsMap.value = getClaimsMap(verifiedClaims.value);
});

/**
 * Added as an easier way instead of importing the schema package.
 */
const validateStateByPath = (
  pathToValidate,
  schemaId = undefined,
  overlays = []
) => {
  const dataToValidate = getStateByPath(pathToValidate);
  const validator = getSubschemaValidator(pathToValidate, schemaId, overlays);
  const isValid = validator(dataToValidate);

  return isValid;
};

const getStateEvidenceTypeByPath = (pathToFind) => {
  const verification = jp.get(pdtfStateVerification.value, pathToFind);
  let evidenceType = "";

  if (
    Array.isArray(verification?.evidence) &&
    verification.evidence.length > 0
  ) {
    const mostRecentEvidence = verification.evidence[0];
    evidenceType = mostRecentEvidence.type;
  }

  return evidenceType;
};

/**
 * Will probably handle getting/updating the state.
 */
export function usePDTF() {
  return {
    attachments,
    createAttachmentsFromVerifiedClaims,
    createStateFromVerifiedClaims,
    createTransaction,
    fetchTransaction,
    findTransactionsByUprn,
    findTransactionsByUser,
    formAttachments,
    getAttachmentsByPath,
    getStateByPath,
    getStateEvidenceTypeByPath,
    getVerifiedClaimsByPath,
    pdtfState,
    pdtfStateVerification,
    setStateByPath,
    transactionError,
    transactionId,
    validateStateByPath,
    verifiedClaims,
    claimsMap,
  };
}

/**
 * Utils - will probably shift a few other methods into here.
 */
export function usePDTFUtils() {
  const toSchemaPathFromArray = (arrayOfPaths = []) => {
    return `/${arrayOfPaths.join("/")}`;
  };

  const flattenAttachments = (dirtyAttachments) => {
    const attachments = [];
    const getAttachmentsFromObject = (obj) => {
      if (Array.isArray(obj)) {
        obj.forEach((item) => {
          getAttachmentsFromObject(item);
        });
      } else if (typeof obj === "object") {
        if (obj.attachment) {
          attachments.push(obj);
        }
        Object.values(obj).forEach((value) => {
          getAttachmentsFromObject(value);
        });
      }
    };
    getAttachmentsFromObject(dirtyAttachments);

    return uniqueAttachments(attachments);
  };

  const isClaimEvidenceAnElectronicRecord = (claimsToCheck) => {
    return isClaimEvidenceMatchesRecordTypes(claimsToCheck, [
      "electronic_record",
    ]);
  };

  const isClaimEvidenceMatchesRecordTypes = (
    claimsToCheck,
    recordTypesToMatch = []
  ) => {
    // Top verification must be the value used for the state.
    if (!Array.isArray(claimsToCheck) || claimsToCheck.length === 0)
      return false;

    const [claimUsedAsState] = claimsToCheck;
    const electronicRecord = claimUsedAsState.verification.evidence.find(
      (evidence) => {
        return recordTypesToMatch.includes(evidence.type);
      }
    );

    return !!electronicRecord;
  };

  const getTitleNumbers = () => {
    const titlesToBeSold = pdtfState.value?.propertyPack?.titlesToBeSold;

    if (!titlesToBeSold) return [];

    const titleNumbers = titlesToBeSold.map((titleToBeSold) => {
      return titleToBeSold.registerExtract.ocSummaryData.Title.TitleNumber;
    });

    return titleNumbers;
  };

  const isLetting = () => {
    const { status } = pdtfState.value || {};
    return ["To let", "Let agreed"].includes(status);
  };

  const isSale = () => !isLetting();

  const getVendorLabel = () => {
    return isLetting() ? "Landlord" : "Seller";
  };

  const getBuyerLabel = () => {
    return isLetting() ? "Tenant" : "Buyer";
  };

  const setDataByPath = (data, pathToUpdate, newValue) => {
    jp.set(data, pathToUpdate, newValue);
  };

  const getDataByPath = (pathToFind = "/") => {
    const matchedState = jp.get(pdtfState.value, pathToFind);
    return matchedState || {};
  };

  // any false booleans in the data must be unchecked checkboxes, we aren't shown as
  // invalid per the schema (which makes them impossible to save) but still represent a
  // missing question
  const countFalseBooleans = (data) => {
    if (typeof data === "boolean" && data === false) return 1;
    if (typeof data === "object" && data !== null) {
      return Object.values(data).reduce(
        (total, element) => (total += countFalseBooleans(element)),
        0
      );
    }
    return 0;
  };

  // find a verificationState for a given path, or the nearest parent if none exists
  const nearestParentVerification = (verificationState, path) => {
    const pathArray = path.split("/");
    const ancestorPaths = pathArray.map((_, index) =>
      pathArray.slice(0, pathArray.length - index).join("/")
    );
    const nearestParentPath = ancestorPaths.find((path) =>
      jp.get(verificationState, path)
    );
    return nearestParentPath
      ? jp.get(verificationState, nearestParentPath)
      : undefined;
  };

  // remove additional properties which don't have a BASPI (initially) ref and
  // those which are not explicitly specified in a dependency
  // as if each oneOf schema had an implied additionalProperties: false
  const withoutAdditionalProperties = (path, originalData, overlay) => {
    const data = structuredClone(originalData);
    const elementSchema = getSubschema(path, undefined, [overlay]);

    if (!elementSchema) return originalData; // an invalid path, leave it alone

    if (elementSchema.type === "array") {
      return data.map((item, index) =>
        withoutAdditionalProperties(`${path}/${index}`, item, overlay)
      );
    }

    if (elementSchema.type === "object") {
      const discriminator = elementSchema.discriminator?.propertyName;
      if (discriminator && data[discriminator]) {
        const oneOfs = elementSchema.oneOf;
        const matchingOneOf = oneOfs.find((oneOf) =>
          oneOf.properties[discriminator].enum.includes(data[discriminator])
        );
        // include both added properties and existing ones at the same level
        const validProperties = Object.keys(matchingOneOf.properties).concat(
          Object.keys(elementSchema.properties)
        );
        Object.keys(data).forEach((property) => {
          if (!validProperties.includes(property)) {
            jp.set(data, `/${property}`, undefined);
          }
        });
      }
      const returnObj = {};
      Object.entries(data).forEach(
        ([property, value]) =>
          (returnObj[property] = withoutAdditionalProperties(
            `${path}/${property}`,
            value,
            overlay
          ))
      );
      return returnObj;
    }
    // const overlayToRefMapping = {
    //   baspiV4: "baspiRef",
    //   nts2023: "ntsRef",
    //   ta6Ed4: "ta6Ref",
    //   ta7Ed3: "ta7Ref",
    //   ta10Ed3: "ta10Ref",
    // };
    // if (
    //   overlay &&
    //   overlayToRefMapping[overlay] &&
    //   !elementSchema[overlayToRefMapping[overlay]]
    // ) {
    //   return undefined;
    // }

    // neither an array nor an object: return the data only if it has a BASPI (initially) ref
    // if (!elementSchema.baspiRef) return undefined;
    return data;
  };

  const renderDataAsHtml = (
    schema,
    state,
    path,
    level = 1,
    omitHeader = false
  ) => {
    // TODO sanitise all user-provided content vs XSS
    const { title, type, items, properties, discriminator, oneOf } = schema;
    const data = jp.get(state, path);
    if (!data) return "";
    let syntheticTitle = "";
    if (title) {
      syntheticTitle = title;
    } else if (title === "") {
      syntheticTitle = ""; // deliberately blank
    } else {
      // no 'title' property present, so we use the property name to create a readable descriptor
      // we could do a better job where the property name is an acronym, but this is a start
      const propertyName = path
        .split("/")
        .pop()
        .replace(/([A-Z])/g, " $1")
        .toLowerCase()
        .trim();
      syntheticTitle =
        propertyName.charAt(0).toUpperCase() + propertyName.slice(1);
    }
    let titleHtml = "";

    if (title) {
      titleHtml = `<span class="state__data-title">${title}</span> `;
    }

    // a data node
    if (["number", "string"].includes(typeof data)) {
      return `<p class="state__data${
        data.length === 0 ? " state__data--is-empty" : ""
      }${
        titleHtml ? " state__data--title" : ""
      }">${titleHtml}<span class="state__data__value">${data.toString()}</span></p>`;
    }
    if (typeof data === "boolean") {
      return `<p class="state__data${
        titleHtml ? " state__data--title" : ""
      }">${titleHtml}<span class="state__data__value">${
        data ? "Yes" : "No"
      }</span></p>`;
    }

    // not data, some kind of structure

    // a standard JSONSchema oneOf construct, probably (like the title deed schema)
    // which allows for either an object or an array of those objects
    if (oneOf && !discriminator) {
      const matchingOneOf = oneOf.find(
        (aOneOf) =>
          aOneOf.type === (Array.isArray(data) ? "array" : typeof data)
      );
      if (matchingOneOf)
        return renderDataAsHtml(matchingOneOf, state, path, level);
    }
    if (type === "array") {
      // data must also be an array to be valid
      const renderedItems = data
        .map((item, index) =>
          renderDataAsHtml(
            items, // the item schema
            state,
            `${path}/${index}`,
            level + 1
          )
        )
        .join("");
      return `${
        omitHeader || title === ""
          ? ""
          : `<h${level} class="state__heading state__heading-${level}">${syntheticTitle}</h${level}>`
      }<div class="state__properties>"${renderedItems}</div>`;
    }

    if (type === "object") {
      let matchingDependencyProperties = {};
      if (discriminator) {
        const { propertyName } = discriminator;
        const matchingOneOf = oneOf.find((aOneOf) => {
          return aOneOf.properties[propertyName].enum.includes(
            data[propertyName]
          );
        });
        // eslint-disable-next-line
        const { [propertyName]: _, ...restOfProperties } =
          matchingOneOf.properties;
        matchingDependencyProperties = restOfProperties;
      }

      const allProperties = { ...properties, ...matchingDependencyProperties };
      const renderedItems = Object.entries(allProperties)
        .map(([property, subSchema]) =>
          renderDataAsHtml(subSchema, state, `${path}/${property}`, level + 1)
        )
        .join("");

      return `${
        omitHeader || title === ""
          ? ""
          : `<h${level} class="state__heading state__heading-${level}">${syntheticTitle}</h${level}>`
      }<div class="state__properties">${renderedItems}</div>`;
    }

    return `Invalid type (${syntheticTitle} ${data} ${type} ${typeof data}, ${JSON.stringify(
      schema
    )})!`;
  };

  const renderSchemaWithVerificationAsHtml = (
    schema,
    state,
    pdtfStateVerification,
    path,
    level = 1
  ) => {
    const {
      baspi4Ref,
      ta6Ref,
      title,
      type,
      items,
      properties,
      discriminator,
      oneOf,
    } = schema;

    let pathArray = path.split("/").slice(1);
    const propertyName = pathArray.pop();

    const data = jp.get(state, path);
    if (!data) return "";

    let titleHtml = "";

    const numberedTitleParts = [];

    numberedTitleParts.push(
      `<span class="state__heading__ref ${
        ta6Ref ? "state__heading__ref--tls" : ""
      }">`
    );
    if (baspi4Ref) {
      numberedTitleParts.push(
        `<span class="state__heading__ref__label state__heading__baspi-ref">${baspi4Ref}</span>`
      );
    }
    if (ta6Ref) {
      numberedTitleParts.push(
        `&nbsp;<span class="state__heading__ref__label state__heading__tls-ref">(${ta6Ref})</span>`
      );
    }
    numberedTitleParts.push(
      `</span><span class="state__heading__title">${
        title || "No title!"
      }</span>`
    );
    const numberedTitle = numberedTitleParts.join("");

    if (title) {
      titleHtml = `<span class="state__data-title">${numberedTitle}</span> `;
    }

    const verification = jp.get(pdtfStateVerification, path);
    let attachmentsForPath = getAttachmentsByPath(path, true);
    let verificationHtml = "";
    let vouchAttachmentHtml = "";

    if (verification?.evidence) {
      let nonVouchEvidence = [];
      verification.evidence.forEach((evidenceItem) => {
        if (evidenceItem.type !== "vouch") {
          nonVouchEvidence.push(evidenceItem.record.source.name);
        }
      });

      if (nonVouchEvidence.length) {
        verificationHtml += `<p class="state__verification">${nonVouchEvidence.join(
          ", "
        )}</p>`;
      }
    }

    if (attachmentsForPath.length) {
      vouchAttachmentHtml += `<p data-path="${path}" class="state__vouch-attachments"><span class="state__vouch-attachments__heading">Documents provided by the seller</span><span class="state__vouch-attachments__main">${attachmentsForPath
        .map((vouchAttachment) => vouchAttachment.attachment.desc)
        .join("<br />")}</span></p>`;
    }

    // a data node
    if (["number", "string", "integer"].includes(type)) {
      return `<p data-path="${path}" class="state__data${
        verificationHtml ? " state__data--verification" : ""
      }${data.length === 0 ? " state__data--is-empty" : ""}${
        titleHtml ? " state__data--title" : ""
      }">${titleHtml}<span class="state__data__value">${data.toString()}</span></p>${verificationHtml}${vouchAttachmentHtml}`;
    }
    if (type === "boolean") {
      return `<p class="state__data${
        titleHtml ? " state__data--title" : ""
      }">${titleHtml}<span class="state__data__value">${
        data ? "Yes" : "No"
      }</span></p>${verificationHtml}${vouchAttachmentHtml}`;
    }

    // not data, some kind of structure
    // a standard JSONSchema oneOf construct, probably (like the title deed schema)
    // which allows for either an object or an array of those objects
    if (oneOf && !discriminator) {
      const matchingOneOf = oneOf.find(
        (aOneOf) =>
          aOneOf.type === (Array.isArray(data) ? "array" : typeof data)
      );
      if (matchingOneOf)
        return renderSchemaWithVerificationAsHtml(
          matchingOneOf,
          state,
          pdtfStateVerification,
          path,
          level
        );
    }

    if (type === "array") {
      // data must also be an array to be valid
      const renderedItems = data
        .map((item, index) =>
          renderSchemaWithVerificationAsHtml(
            items, // the item schema
            state,
            pdtfStateVerification,
            `${path}/${index}`,
            level + 1
          )
        )
        .join("");
      return (
        (numberedTitle === ""
          ? ""
          : `<h${level} class="state__heading state__heading-${level}${
              verificationHtml ? " state__heading--verification" : ""
            }">${numberedTitle}</h${level}>`) +
        `<div class="state__properties${
          verificationHtml ? " state__properties--verification" : ""
        }>"${renderedItems}${vouchAttachmentHtml}${verificationHtml}</div>`
      );
    }

    if (type === "object") {
      let matchingDependencyProperties = {};
      if (discriminator) {
        const { propertyName } = discriminator;
        const matchingOneOf = oneOf.find((aOneOf) => {
          return aOneOf.properties[propertyName].enum.includes(
            data[propertyName]
          );
        });

        const { [propertyName]: _, ...restOfProperties } =
          matchingOneOf.properties;
        matchingDependencyProperties = restOfProperties;
      }

      const allProperties = { ...properties, ...matchingDependencyProperties };
      const renderedItems = Object.entries(allProperties)
        .map(([property, subSchema]) =>
          renderSchemaWithVerificationAsHtml(
            subSchema,
            state,
            pdtfStateVerification,
            `${path}/${property}`,
            level + 1
          )
        )
        .join("");

      return (
        (numberedTitle === ""
          ? ""
          : `<h${level} class="state__heading state__heading-${level}${
              verificationHtml ? " state__heading--verification" : ""
            }">${numberedTitle}</h${level}>`) +
        `<div class="state__properties${
          verificationHtml ? " state__properties--verification" : ""
        }">${renderedItems}${vouchAttachmentHtml}${verificationHtml}</div>`
      );
    }
    return `Invalid type (${type}, ${JSON.stringify(schema)})!`;
  };

  const renderSchemaWithVerificationAsObjects = (
    schema,
    state,
    pdtfStateVerification,
    path,
    level = 1
  ) => {
    const rawObjects = renderSchemaWithVerificationAsRawObjects(
      schema,
      state,
      pdtfStateVerification,
      path,
      (level = 1)
    );

    if (!rawObjects) return [];
    let objects = [];
    // associate yesNo objects with their parents
    rawObjects.forEach((object) => {
      const propertyName = object.path.split("/").pop();
      if (propertyName.includes("yesNo") && object.title === "") {
        // just set the value to the parent object, don't push this one
        objects[objects.length - 1].data = object.data;
      } else {
        // assume a trailing integer is an array index
        const index = parseInt(propertyName);
        if (!isNaN(index)) object.arrayHeadingCounter = index + 1;
        objects.push(object);
      }
    });
    return objects;
  };

  const renderSchemaWithVerificationAsRawObjects = (
    schema,
    state,
    pdtfStateVerification,
    path,
    level = 1
  ) => {
    const { title, type, items, properties, discriminator, oneOf } = schema;

    const data = jp.get(state, path);
    if (!data) return null;

    let syntheticTitle = "";
    if (title) {
      syntheticTitle = title;
    } else if (title === "") {
      syntheticTitle = ""; // deliberately blank
    } else {
      // no 'title' property present, so we use the property name to create a readable descriptor
      // we could do a better job where the property name is an acronym, but this is a start
      const propertyName = path
        .split("/")
        .pop()
        .replace(/([A-Z])/g, " $1")
        .toLowerCase()
        .trim();
      syntheticTitle =
        propertyName.charAt(0).toUpperCase() + propertyName.slice(1);
    }

    const verification = nearestParentVerification(pdtfStateVerification, path);
    let attachments = getAttachmentsByPath(path, true);
    if (attachments.length < 1) attachments = undefined;

    // a data node
    if (["number", "string", "integer"].includes(type)) {
      return [
        {
          path,
          level,
          title: syntheticTitle,
          data: data.toString(),
          verification,
          attachments,
        },
      ];
    }
    if (type === "boolean") {
      return [
        {
          path,
          level,
          title: syntheticTitle,
          data: data ? "Yes" : "No",
          verification,
          attachments,
        },
      ];
    }

    // not data, some kind of structure
    const titleObject = {
      path,
      level,
      title: syntheticTitle,
      verification,
      attachments,
    };

    // a standard JSONSchema oneOf construct, probably (like the title deed schema)
    // which allows for either an object or an array of those objects
    if (oneOf && !discriminator) {
      const matchingOneOf = oneOf.find(
        (aOneOf) =>
          aOneOf.type === (Array.isArray(data) ? "array" : typeof data)
      );
      if (matchingOneOf)
        return renderSchemaWithVerificationAsRawObjects(
          matchingOneOf,
          state,
          pdtfStateVerification,
          path,
          level
        );
    }

    let renderedItems = [];

    if (type === "array") {
      // data must also be an array to be valid
      if (!Array.isArray(data)) {
        console.log("non array to be rendered at path", path);
        return null;
      }
      renderedItems = data.map((item, index) =>
        renderSchemaWithVerificationAsRawObjects(
          items, // the item schema
          state,
          pdtfStateVerification,
          `${path}/${index}`,
          level + 1
        )
      );
    }

    if (type === "object") {
      let matchingDependencyProperties = {};
      if (discriminator) {
        const { propertyName } = discriminator;
        const matchingOneOf = oneOf.find((aOneOf) => {
          return aOneOf.properties[propertyName].enum.includes(
            data[propertyName]
          );
        });
        // eslint-disable-next-line
        const { [propertyName]: _, ...restOfProperties } =
          matchingOneOf.properties;
        matchingDependencyProperties = restOfProperties;
      }

      const allProperties = { ...properties, ...matchingDependencyProperties };
      renderedItems = Object.entries(allProperties).map(
        ([property, subSchema]) =>
          renderSchemaWithVerificationAsRawObjects(
            subSchema,
            state,
            pdtfStateVerification,
            `${path}/${property}`,
            level + 1
          )
      );
    }
    const flattenedFilteredItems = renderedItems.filter((item) => item).flat();
    return [titleObject, ...flattenedFilteredItems];
    // return { error: `Invalid type (${type}, ${JSON.stringify(schema)})!` };
  };

  const fieldTitlePathsMap = {};

  const getSchemaTitles = (schemaOverlay = "ta6ed4", refName = "ta6Ref") => {
    const schema = getTransactionSchema(undefined, [schemaOverlay]);
    if (Object.entries(fieldTitlePathsMap) > 0) return fieldTitlePathsMap;

    traverse(schema).forEach(function (element) {
      if (element && element[refName]) {
        fieldTitlePathsMap[element[refName]] = element.title;
      }
    });

    return fieldTitlePathsMap;
  };

  const extractedDocumentResults = (fileId) => {
    // retrieve the processing result from the file record / storage
    // for now, use canned data
    const extractionResults = cannedExtractionResults;
    const fieldTitlePaths = getSchemaTitles();

    // add title properties to the extraction results
    const results = Object.entries(extractionResults.results).map(
      ([ref, result]) => {
        return {
          ref,
          title: fieldTitlePaths[ref] || ref,
          ...result,
        };
      }
    );
    return { pageImageUrls: extractionResults.pageImageUrls, results };
  };

  // Quick patch for the BASPI full view where attachments are now references to external links.
  const isDownloadableUrl = (url) => {
    const allowlist = ["storage.googleapis.com"];
    try {
      const parsedUrl = new URL(url);
      const isUrlDownloadableDomain = allowlist.includes(parsedUrl.hostname);
      return isUrlDownloadableDomain;
    } catch (ex) {
      return false;
    }
  };

  /**
   * This exists as attachments can contain sources as well as downloadable files.
   */
  const filterAttachmentsByDocument = (attachmentsToFilter) => {
    const allowlist = ["storage.googleapis.com"];
    const filteredDocuments = attachmentsToFilter.filter(({ attachment }) => {
      try {
        const parsedUrl = new URL(attachment.url);
        const isUrlDownloadableDomain = allowlist.includes(parsedUrl.hostname);
        return isUrlDownloadableDomain;
      } catch (ex) {
        return false;
      }
    });

    return filteredDocuments;
  };

  const getAttachmentsFromClaims = (claims) => {
    // eslint-disable-next-line
    const attachments = structuredClone(claims).reduce(
      (attachmentsCollection, claim) => {
        const evidence = claim?.verification?.evidence;

        if (!Array.isArray(evidence)) return attachmentsCollection;

        evidence.forEach((evidenceItem) => {
          const attachments = evidenceItem?.attachments;

          if (!attachments) return;

          attachmentsCollection = [...attachmentsCollection, ...attachments];
        });

        return attachmentsCollection;
      },
      []
    );

    return attachments;
  };

  return {
    countFalseBooleans,
    extractedDocumentResults,
    filterAttachmentsByDocument,
    flattenAttachments,
    getAttachmentsFromClaims,
    getBuyerLabel,
    getDataByPath,
    getSchemaTitles,
    getTitleNumbers,
    getVendorLabel,
    isClaimEvidenceAnElectronicRecord,
    isDownloadableUrl,
    isLetting,
    isSale,
    renderDataAsHtml,
    renderSchemaWithVerificationAsHtml,
    renderSchemaWithVerificationAsObjects,
    setDataByPath,
    toSchemaPathFromArray,
    uniqueAttachments,
    withoutAdditionalProperties,
  };
}

<script setup>
import MenuIcon from "../components/library/icons/IconMenu.vue";
import useAuth from "@/composables/auth";
import useSiteLayout from "@/composables/siteLayout";
import useUserProfile from "@/composables/userProfile";
import useBranding from "@/composables/branding";
import { ref, onUnmounted, onMounted, computed } from "vue";

const { isUserAuthenticated } = useAuth();
const { isAccountPage, isLoading } = useSiteLayout();
const { userTransactions, isExecutive, isApplicantForTransaction } =
  useUserProfile();
const { brandLogo, brandLogoScale, brandName } = useBranding();

const getStartedRoute = { name: "agent.sign-up" };
const isNavigationVisible = ref(false);
const navigationTrigger = ref(false);

const hasMultipleTransactions = computed(
  () => userTransactions.value.length > 1
);

const hasSingleTransaction = computed(
  () => userTransactions.value.length === 1
);
const transactionId = computed(() => {
  const [firstUserTransactionRel] = userTransactions.value;

  if (firstUserTransactionRel) {
    return firstUserTransactionRel.transactionId;
  }

  return null;
});
// This is to temporarily force the sign up journeys to focus on the agents.

const toggleNavigationVisibility = () => {
  if (isNavigationVisible.value) {
    closeMenu();
  } else {
    openMenu();
  }
};

const closeMenu = () => {
  document.body.classList.remove("body--site-account-navigation");
  isNavigationVisible.value = false;
};

const openMenu = () => {
  document.body.classList.add("body--site-account-navigation");
  isNavigationVisible.value = true;
};

const documentClick = (event) => {
  let element = navigationTrigger.value;
  let target = null;
  if (event && event.target) {
    target = event.target;
  }

  // This is here to only close the navigation if
  // the user clicks a link that needs to load a page.
  // Don't close the menu if the user clicks on a
  // button that shows a sub navigation.
  if (
    element &&
    element !== target &&
    !element.contains(target) &&
    target.nodeName === "A" &&
    event.target.className !== "navigation__main" &&
    isNavigationVisible.value
  ) {
    closeMenu();
  }
};

const myAccountRoute = computed(() => {
  if (isExecutive.value) return { name: "back-office.dashboard" };

  return { name: "user.account" };
});

onMounted(() => {
  document.addEventListener("click", documentClick);
});

onUnmounted(() => {
  closeMenu();
  document.removeEventListener("click", documentClick);
});

const singleTransactionLabel = computed(() => {
  if (isApplicantForTransaction(transactionId.value)) {
    return "Dashboard";
  }

  return "My property";
});
</script>

<template>
  <header class="site-account-header" role="banner">
    <div class="site-account-header__content" v-if="!isLoading">
      <router-link
        :to="{ path: '/' }"
        class="site-account-logo"
        :class="{
          [`site-account-logo--scale-${brandLogoScale}`]: !!brandLogoScale,
        }"
        data-test="header-logo"
        ><img
          :src="brandLogo"
          :alt="brandName"
          class="site-account-logo__graphic"
        />
      </router-link>

      <div
        class="site-account-navigation"
        :class="{ 'site-account-navigation--active': isNavigationVisible }"
        role="navigation"
      >
        <div class="site-account-navigation__header">
          <span
            class="site-account-navigation__action"
            v-if="!isUserAuthenticated"
          >
            <router-link
              :to="getStartedRoute"
              class="site-account-navigation-menu__link-button site-account-navigation-menu__link-button--action"
              active-class="site-account-navigation-menu__link-button--action-active"
              data-test="header-register"
              >Get started</router-link
            ></span
          >
          <span
            ref="navigationTrigger"
            class="site-account-navigation__trigger"
            aria-label="Menu"
            @click="toggleNavigationVisibility"
          >
            <menu-icon
              class="site-account-navigation__signifier"
              :is-open="isNavigationVisible"
            />
          </span>
        </div>

        <div class="site-account-navigation__main">
          <div class="site-account-navigation__main__content">
            <div class="site-account-navigation-menu">
              <ul class="site-account-navigation-menu__collection">
                <template v-if="!isAccountPage">
                  <li
                    class="site-account-navigation-menu__item site-account-navigation-menu__item--site"
                  >
                    <router-link
                      :to="{ name: 'agents.landing' }"
                      class="site-account-navigation-menu__link"
                      active-class="site-account-navigation-menu__link--active"
                      >For Agents</router-link
                    >
                  </li>
                  <li
                    class="site-account-navigation-menu__item site-account-navigation-menu__item--site"
                  >
                    <router-link
                      :to="{ name: 'sellers.landing' }"
                      class="site-account-navigation-menu__link"
                      active-class="site-account-navigation-menu__link--active"
                      >For Sellers</router-link
                    >
                  </li>
                  <li
                    class="site-account-navigation-menu__item site-account-navigation-menu__item--site"
                  >
                    <router-link
                      :to="{ name: 'conveyancers.landing' }"
                      class="site-account-navigation-menu__link"
                      active-class="site-account-navigation-menu__link--active"
                      >For Conveyancers</router-link
                    >
                  </li>
                  <li
                    class="site-account-navigation-menu__item site-account-navigation-menu__item--site"
                  >
                    <router-link
                      :to="{ name: 'pricing' }"
                      class="site-account-navigation-menu__link"
                      active-class="site-account-navigation-menu__link--active"
                      >Pricing</router-link
                    >
                  </li>
                  <li
                    class="site-account-navigation-menu__item site-account-navigation-menu__item--site"
                  >
                    <router-link
                      :to="{ name: 'about' }"
                      class="site-account-navigation-menu__link"
                      active-class="site-account-navigation-menu__link--active"
                      >About us</router-link
                    >
                  </li>
                </template>

                <template v-if="isUserAuthenticated">
                  <template v-if="!isExecutive">
                    <li
                      class="site-account-navigation-menu__item site-account-navigation-menu__item--user"
                      v-if="hasMultipleTransactions"
                    >
                      <router-link
                        :to="{ name: 'user.home' }"
                        class="site-account-navigation-menu__link"
                        active-class="site-account-navigation-menu__link--active"
                        >My properties</router-link
                      >
                    </li>
                    <li
                      class="site-account-navigation-menu__item site-account-navigation-menu__item--user"
                      v-else-if="hasSingleTransaction"
                    >
                      <router-link
                        :to="{
                          name: 'property.view',
                          params: { transactionId },
                        }"
                        class="site-account-navigation-menu__link site-account-navigation-menu__link--transaction"
                        active-class="site-account-navigation-menu__link--transaction-active"
                        data-test="mover-dashboard-link"
                        >{{ singleTransactionLabel }}</router-link
                      >
                    </li>
                  </template>
                  <li
                    class="site-account-navigation-menu__item site-account-navigation-menu__item--user"
                  >
                    <router-link
                      :to="myAccountRoute"
                      class="site-account-navigation-menu__link-button site-account-navigation-menu__link-button--primary"
                      active-class="site-account-navigation-menu__link-button--active"
                      >My account</router-link
                    >
                  </li>
                </template>
                <template v-else>
                  <li
                    class="site-account-navigation-menu__item site-account-navigation-menu__item--user"
                  >
                    <router-link
                      :to="{ name: 'sign-in' }"
                      class="site-account-navigation-menu__link"
                      active-class="site-account-navigation-menu__link--active"
                      data-test="header-login"
                      >Sign in</router-link
                    >
                  </li>
                  <li
                    class="site-account-navigation-menu__item site-account-navigation-menu__item--user"
                  >
                    <router-link
                      :to="getStartedRoute"
                      class="site-account-navigation-menu__link-button site-account-navigation-menu__link-button--action"
                      active-class="site-account-navigation-menu__link-button--action-active"
                      data-test="header-register"
                      >Get started</router-link
                    >
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="scss">
/**
 *----------------------------------------
  VARIABLES
 *----------------------------------------
 */
@import "../assets/styles/variables/button";

$account-layout-max-width: $layout-max-width;
$account-layout-navigation-background-color: #f5f9f9;
$account-layout-navigation-max-width: 400px;

.body--site-account-navigation {
  overflow: hidden;
}

/**
 *----------------------------------------
  HEADER
 *----------------------------------------
 */

/**
  * 1) Prevents the header form hiding is the site content doesn't wrap to the viewport.
  *    It will still "break" the layout but at it will be usable.
  */
.site-account-header {
  transform: translate3d(0, 0, 0);
  background: #fff;
  display: flex;
  height: $header-height;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 100vw; /* 1 */
  z-index: 300;
}

.site-account-header--onboarding {
  background: #fff;
}

.site-account-header__content {
  display: flex;
  position: relative;
  margin: 0 auto;
  max-width: $account-layout-max-width;
  width: 100%;
}

.site-account-logo {
  display: block;
  left: $site-gutter;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.site-account-logo__graphic {
  height: 32px;
  width: auto;
}

@include mq("medium-viewport") {
  .site-account-header--onboarding {
    background: transparent;
  }

  .site-account-header {
    background: transparent;
    position: static;
    height: auto;

    .site-account-logo {
      transform: none;
      position: static;
      margin: 24px 0;
    }

    .site-account-logo--scale-strong {
      margin: 16px 0;
      width: inherit;

      .site-account-logo__graphic {
        height: 56px;
      }
    }
  }

  .site-account-logo__graphic {
    height: 40px;
    width: auto;
  }

  .site-account-header__content {
    align-items: center;
  }
}

/**
 *----------------------------------------
  NAVIGATION
 *----------------------------------------
 */

.site-account-navigation {
  display: flex;
  right: 0;
  position: fixed;
  top: 0;
  width: 100%;
  justify-content: flex-end;
  z-index: 1;
}

.site-account-navigation--active {
  z-index: 10;

  .site-account-navigation__main {
    left: 0;
  }
}

.site-account-navigation__header {
  align-items: center;
  display: flex;
  min-height: $header-height;
  position: relative;
}

.site-account-navigation__main {
  @include transition("left");
  background-color: #fff;
  height: 100vh;
  left: 100%;
  padding-top: $header-height;
  position: absolute;
  top: 0;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  width: 100%;
}

.site-account-navigation__action {
  display: block;
  margin-right: 64px;
}

.site-account-navigation__main__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 gutter() * 7
    calc(#{stack("stronger")} + env(safe-area-inset-bottom));
}

.site-account-navigation-menu__collection {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.site-account-navigation-menu__item {
  display: inline;
  font-weight: 500;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;

  & + .site-account-navigation-menu__item {
    margin-top: stack();
  }
}

/**
 * 1. Used to style a button element.
 */
.site-account-navigation-menu__link {
  @include transition();

  background: transparent;
  border: 0;
  border-radius: 12px;
  color: #373a4d;
  cursor: pointer;
  /* 1 */
  display: inline-block;
  font-family: inherit;
  /* 1 */
  font-size: 16px;
  /* 1 */
  font-weight: 500;
  /* 1 */
  outline: none;
  padding: stack() 0;
  position: relative;
  /* 1 */
  text-decoration: none;
  text-align: center;
  /* 1 */

  &:focus,
  &:hover {
    // background: $account-layout-navigation-background-color;
    text-decoration: none;
    color: $color-primary;
  }

  &:after {
    content: $char-nbsp;
    height: 2px;
    display: block;
    background: transparent;
    border-radius: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.site-account-navigation-menu__link--active {
  color: $color-primary;

  &:after {
    background: $color-primary;
  }
}

.site-account-navigation-menu__link-button {
  @include transition();

  background: #fff;
  border: 0;
  border-radius: radius(weak);
  color: #373a4d;
  cursor: pointer;

  display: inline-block;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  padding: stack() gutter(stronger);
  position: relative;
  text-decoration: none;
  text-align: center;

  &:hover {
    background: $color-secondary-10;
  }
}

.site-account-navigation-menu__link-button--active {
  color: $color-secondary-10;
}

.site-account-navigation__trigger {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: 100%;
  justify-content: center;
  min-width: $header-height;
  padding: 0 gutter("layout");
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 10;
}

.site-account-navigation-menu__item--site {
  & + .site-account-navigation-menu__item--user {
    margin-top: stack(strongest) + stack(strongest);
  }
}

.site-account-navigation-menu__link-button--primary {
  background: $color-primary;
  border-color: $color-primary;
  color: $color-on-primary;

  &:hover {
    background: $button-hover-background;
    border-color: $button-hover-border-color;
    color: $button-hover-on-background;
  }
}

.site-account-navigation-menu__link-button--action {
  background: $color-primary;
  border-color: $color-primary;
  color: $color-on-primary;
  border-radius: radius(weak);
  padding: 6px gutter(strong);

  &:hover {
    background: $color-primary;
    border-color: $color-primary;
    color: #14424b;
  }
}

@include mq("medium-viewport") {
  .site-account-navigation {
    position: relative;
    margin-left: gutter(strongest) + gutter(strong);
  }

  .site-account-navigation__header {
    display: none;
  }

  .site-account-navigation__main {
    background: transparent;
    padding-top: 0;
    height: auto;
    position: static;
  }

  .site-account-navigation__main__content {
    padding: 0;
    height: auto;
  }

  .site-account-navigation-menu__collection {
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
  }

  .site-account-navigation-menu {
    flex: 1;
  }

  .site-account-navigation-menu__item {
    & + .site-account-navigation-menu__item {
      margin-top: 0;
      margin-left: gutter(strongest);
    }
  }

  .site-account-navigation-menu__item--site {
    & + .site-account-navigation-menu__item--user {
      padding-left: gutter(strongest) + gutter(strong);
    }
  }

  .site-account-navigation-menu__item--site {
    & + .site-account-navigation-menu__item--site {
      margin-left: gutter(stronger);
    }
  }

  .site-account-navigation-menu__link-button--action {
    padding: 12px gutter(stronger);
  }
}
</style>

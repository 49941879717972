import redirectIfNoConveyancerParticipant from "../guards/redirectIfNoConveyancerParticipant";

export default [
  {
    path: "manage/participants/add/lawyer",
    name: "participant.conveyancer.add",
    component: () => import("../../views/conveyancer/AddConveyancer.vue"),
    meta: {
      title: "Your property lawyer",
    },
  },
  {
    path: "manage/participants/add/lawyer/review",
    name: "participant.conveyancer.review",
    beforeEnter: redirectIfNoConveyancerParticipant,
    component: () => import("../../views/conveyancer/ReviewConveyancer.vue"),
    meta: {
      title: "Review your property lawyer",
    },
  },
  {
    path: "property-pack/complete",
    name: "property-pack.complete",
    beforeEnter: redirectIfNoConveyancerParticipant,
    component: () => import("../../views/conveyancer/ConveyancerComplete.vue"),
    meta: {
      title: "Complete",
    },
  },
  {
    path: "manage/sale-ready/review",
    name: "sale-ready.review.sale",
    props: true,
    component: () =>
      import("../../views/conveyancer/ConnellsConfirmationSale.vue"),
    meta: {
      title: "Your property lawyer",
    },
  },
  {
    path: "manage/sale-ready/complete",
    name: "sale-ready.complete.sale",
    component: () => import("../../views/conveyancer/ConnellsCompleteSale.vue"),
    meta: {
      title: "Complete",
    },
  },
  {
    path: "manage/sale-ready/purchase/review",
    name: "sale-ready.review.purchase",
    props: true,
    component: () =>
      import("../../views/conveyancer/ConnellsConfirmationPurchase.vue"),
    meta: {
      title: "Your property lawyer",
    },
  },
  {
    path: "manage/sale-ready/purchase/complete",
    name: "sale-ready.complete.purchase",
    component: () =>
      import("../../views/conveyancer/ConnellsCompletePurchase.vue"),
    meta: {
      title: "Complete",
    },
  },
];
